.tags {
	&__list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		gap: 7px;
		margin-bottom: .5rem;
		padding-left: 2px;
		padding-right: 2px;
		background-color: #fff;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
	}
	&__input {
		display: flex;
		flex: 1;
		min-width: 120px;
		display: block;
		height: calc(1.5em + 0.75rem + 2px);
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: none;
		transition: none;
		&:focus {
			color: #495057;
			background-color: #fff;
			border-color: none !important;
			outline: 0;
			box-shadow: none !important;
		}
	}
	&__tag {
		height: 24px;
		display: flex;
		padding: 0 .5rem;
		margin: 9px 0 0 0;
		background-color: rgb(0 123 255 / 45%);
		border-radius: 30px;
		cursor: pointer;
	}
}
.form-check-input:checked, .form-check-input:focus  {
	background-color: $text-color-active;
	border-color: $color-primary-active;
	box-shadow: none;
}
.form-control:focus{
	border-color: $color-primary-active;
	box-shadow: none;
	background: $base-gradient;
}

.textarea.form-control:active, .textarea.form-control:focus, .textarea.form-control:hover {
	outline: 2px solid $color-primary-active !important;

}