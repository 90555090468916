.bitquery-inputUrl {
  background: linear-gradient(135deg, #FCFAFF 0%, #F8EFFF 70%);
  border-radius: $border-radius;
  font-size: 0.9rem;
  line-height: 18px;
  border: none;

  &::placeholder {
    color: $base-font-color;
  }

  &:active, &:target, &:focus {
    outline: transparent !important;
    box-shadow: none;
  }
}

.input-url-fix {
  border: none;
  //height: 30px;
  font-size: 0.9rem;
  width: 100%;


  &:hover, &:focus, &:active {
    outline: transparent;
  }
}

.input-group-fix {
  //background: $base-gradient;
  height: 32.6px;
  font-size: 0.9rem;

  border: 1px solid $border-color;
  overflow: hidden;
}


.drop-down-fix {
  flex: 0 0 100px; /* фиксированная ширина, не растягивается */
  width: 100px;
  max-width: 70px;


  border-left: 1px solid transparent;
  color: $base-font-color;
  border-right: 1px solid $border-color;
  border-radius: $border-radius 0 0 5px;
  background:$base-gradient;
  font-size: 0.9rem;
  cursor: pointer;
  border-top: transparent;
  border-bottom: transparent;
  transition: filter 0.2s ease;
  &:focus, &active, &target, &:hover {
    border-color:#ffffff !important;
    outline: none;
    box-shadow: none;
    filter: brightness(0.95);
  }

}


.bitquery-little-btn {
  height: 32px;
  width: 32px;
  cursor: pointer;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  background: $base-gradient;
  padding: 4px 10px;
  font-size: 0.8rem;
  color: $base-font-color;
  transition: transform 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease;

  &:hover {
    transform: translateY(-0.5px);
    box-shadow: 0 4px 10px rgba($color-primary-hover, 0.15);
    border-color: $color-primary-hover;
  }

  &:active {
    transform: translateY(0px);
    box-shadow: 0 2px 6px rgba($color-primary-active, 0.1);
    border-color: $color-primary-active;
  }

  &:focus {
    outline: none;
    border-color: $color-primary-hover;
    box-shadow: 0 0 0 2px rgba($color-primary-hover, 0.15);
  }
}

.bi-code-slash:hover {
  background-color: inherit;
}

