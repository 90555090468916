@-moz-document url-prefix() {
  html {
    scrollbar-width: thin;
    scrollbar-color: $color-primary-active $base-teg-color;
  }
}
a {
  outline: none;
  border: none;
  box-shadow: none !important;
  text-decoration: none !important;
  &:hover, &:focus, &:target {
    text-decoration: none !important;
    outline: transparent;
    background-color: transparent !important;

  }
}
i{
  &:hover, &:focus, &:target,&:active {
   background-color: transparent !important;

  }
}
button {
  outline: transparent
}
body{
  font-family: $base-font-family !important;
}

::-webkit-scrollbar-thumb {
  background: $background-gray-d;
  border-radius: 8px;
  &:hover{
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }
}

::-webkit-scrollbar-track {
  border: 1px solid $border-color;
  border-radius: $border-radius;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
input{
  &::placeholder {
    color: $text-color-gray-l;
  }

  &:active, &:target, &:focus {
    @include focus-style;


  }
  &:focus{
    border-color: $text-color-active;

    box-shadow: none;
  }
}
