@mixin focus-style {
  outline: 2px solid $color-primary-active !important;
}

@mixin link-style {
  display: flex;
  border-radius: $border-radius;
  align-items: center;
  text-decoration: none !important;
  color: inherit;

  &:hover, &:target {
    cursor: pointer;
    text-decoration: none;
    border-color: transparent;
    color: inherit;
    background: $base-gradient !important;
  }

  &:active, &:focus {
    color: inherit;
    background: $base-gradient !important;
  }
}

@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;

}


@mixin hover-btn {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  background: $base-gradient;
  font-size: 0.9rem;
  color: $base-font-color;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;

  &:hover {
    transform: translateY(-0.5px);
    box-shadow: 0 6px 14px rgba($border-color, 0.25);
    border-color: $color-primary-hover;
  }

  &:active {
    transform: translateY(0px);
    box-shadow: 0 2px 8px rgba($color-primary-active, 0.2);
    border-color: $color-primary-hover;
  }
}

@mixin transition-btn {
  transition: color 0.15s, border 0.15s, box-shadow 0.15s;

}