.chat-bubble-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0;
  color: #3f3f46;
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
  white-space: nowrap;
  background: linear-gradient(270deg, #f6eafd, #e3e3ff, #fcecff, #e9e6ff);
  background-size: 400% 400%;
  animation: gradientAnimation 10s ease infinite, fadeIn 0.5s ease-in-out;
}

.chat-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 15px;
  border-width: 5px;
  border-style: solid;
  border-color: #e3e3ff transparent transparent transparent;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}



@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.chat-bubble-iframe {
  width: 500px;
  height: calc(100vh - 100px);
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: $button-shadow;
  background: $base-gradient;
  border: 0.5px solid $base-color-border;
}
.chat-bubble-button {
  width: $button-size;
  height: $button-size;
  border-radius: 50%;
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;
  background: linear-gradient(135deg, #d6b9ff, #bda6ff, #f6b4ff, #dab0ff);
  background-size: 400% 400%;
  box-shadow: $button-shadow;

  animation: buttonGradient 8s ease infinite;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(189,166,255,0.3);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 3px 6px rgba(189,166,255,0.2);
  }

  &:focus {
    outline: none;
    box-shadow: $button-focus-shadow;
  }
}

@keyframes buttonGradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}



.chat-bubble-icon {
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;

  .chat-bubble-button:hover & {
    transform: scale(1.1);
  }
}

.chat-bubble-close-icon {
  width: 60%;
  height: 60%;
  transition: transform 0.3s ease-in-out;

  .chat-bubble-button:hover & {
    transform: scale(1.1);
  }
}
