//$base-gradient: linear-gradient(180deg, #F5F5F5 0%, #FFF6F9 100%);
$base-gradient: linear-gradient(135deg, #FCFAFF 0%, #F8EEFF 100%);

$base-font-family: "DM Sans", sans-serif;
$base-font-color: #3f3f46;
$base-color-border: #FFC7DA;
$base-teg-color: #FFEAF1;

$button-color: #CA3568;

//$color-primary-hover: #FF6A9E;
//$color-primary-active: #CE4372;
$color-primary-active: #bda6ff;
$color-primary-hover: #dab0ff;

$background-gray-l: #f8f8f8;
$background-gray-m: #f1f1f1;
$background-gray-d: #c1c1c1;

$text-color-active:#3f1f8a;
$text-color-gray-d: #646464;
$text-color-gray-l: #828486;

$button-size: 35px;
$button-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
$button-focus-shadow: 0px 0px 8px 2px rgba(255, 255, 255, 0.8), 0px 4px 6px rgba(0, 0, 0, 0.2);

$border-color:#ece7ff;
$border-radius: 8px;
$border-teg-radius: 16px;