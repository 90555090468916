//Links
.bitquery-link_active {
  cursor: default;
  color: $text-color-active !important;
}

.bitquery-nav_item {
  color: $base-font-color !important;
}

.bitquery-links-doc {
  color: $color-primary-active !important;

  &:hover, &:active, &:focus {
    color: $color-primary-hover !important;

  }
}
.bitquery-links{
  text-wrap-mode: nowrap;
  padding: 7px 3px;
}
.bitquery-links-item{
  padding-left:1rem;
  font-size:0.9rem;
}
//Icons
.bitquery-ico {
  color: $base-font-color !important;

  &:hover, &:active, &:focus {
    color: $color-primary-hover !important;

  }
}
.bitquery-ico-popup{
  color: $text-color-active !important;

  &:hover, &:active, &:focus {
    color: $color-primary-hover !important;

  }
}
.bitquery-ico-gray {
  color: $background-gray-d !important;
}

.bitquery-ico-black {
  color: $base-font-color !important;

  &:hover, &:focus {
    color: $color-primary-hover !important;
  }
}

//Buttons

.save-btn, .bitquery-btn, .cancel-btn {
  background: $base-gradient;
  padding: 4px 10px;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  font-size: 0.9rem;
  color: $base-font-color;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;

  &:hover {
    transform: translateY(-0.5px);
    box-shadow: 0 6px 14px rgba($border-color, 0.25);
    border-color: $color-primary-hover;
  }

  &:active {
    transform: translateY(0px);
    box-shadow: 0 2px 8px rgba($color-primary-active, 0.2);
    border-color: $color-primary-hover;
  }

  &:focus {
    outline: none;
    border-color: $color-primary-hover;
    box-shadow: 0 0 0 3px rgba($border-color, 0.2);
  }
}

.save-btn {
  color: #006400;
}

.cancel-btn {
  color: #B11A04;
}
.modal-content{
  border:none !important;
}

//teg
.bitquery-teg, .bitquery-teg-edit {
  background: $base-gradient;
  padding: 4px 8px;
  border-radius: $border-teg-radius;
  color: $text-color-gray-l;
  font-size: 11px;
  line-height: 13px;
}

.bitquery-teg-edit {
  font-size: 0.9rem;
  height: 100%;
  padding: 5px 8px;
  border: 1px solid $border-color;
  border-radius: $border-teg-radius;
}

.bitquery-teg-color {
  background-color: $text-color-active;
  padding: 4px 8px;
  border-radius: $border-teg-radius;
  color: #ffffff;
  font-size: 11px;
  line-height: 13px;
  margin-left: auto;
}

.bitquery-teg-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 0 7px;
  margin: 0.5rem 0;
  padding: 0 3px;
  border: 1px solid $border-color;
  border-radius: $border-radius;
}

.bitquery-addNewTab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bitquery-dropdown {
  > .nav-link.dropdown-toggle {
    @extend .bitquery-btn;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 4px 10px;

    &::after {
      border-top-color: $text-color-active;
    }
  }
}


