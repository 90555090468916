@import "src/scss/variables.scss";
@import "src/scss/mixins.scss";

$top-bar-height: 44px;

.response-time {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 1;

  i {
    color: green;
    font-weight: bold;
  }
}

.bitqueditor {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

[contenteditable="true"] {
  user-select: text;
}

.Appb {
  height: 100%;
  width: 100%;
  display: flex;
  margin: 0 0 -11px 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.result-wrapper {
  overflow-y: auto;
}

.streaming-wrapper {
  flex-direction: column-reverse;
  overflow-y: auto;

  .CodeMirror-scroll {
    overflow: hidden !important;
  }
}

.result-time {
  background-color: #f6f7f8;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.result-window {
  display: none;
  width: 100%;
  height: 100%;
  overflow: auto;

  &-json.result-window-active {
    display: flex;
    flex: 1 1;
  }

  &-chart.result-window-active {
    display: block;
  }

  &-chart {
    overflow-x: hidden;
  }

  .CodeMirror {
    height: 100%;
  }
}

.App-link {
  color: #61dafb;
}

.error {
  &__wrapper {
    width: 100%;
    min-height: 150px;
    height: 150px;
    border-radius: 0 0 10px 10px;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #ced4da;
    overflow: auto;
    padding: 0 10px;

    &__container {
      position: relative;
    }

    &__close {
      position: absolute;
      z-index: 10;
      left: 50%;
      transform: translateX(-50%);
      top: 149px;
      border-top: 20px solid #ced4da;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      width: 50px;
      cursor: pointer;

      i {
        position: absolute;
        top: -17px;
        left: 8px;
        opacity: .5;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__message {
    text-align: justify;

    &:before {
      content: ' ';
      display: inline-block;
      margin: 0 3px -4px 0;
      background: url('../../assets/images/error.svg') center no-repeat;
      height: 25px;
      width: 16px;
    }
  }
}

.widget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: .5 1 0%;
  //padding: 0 0 20px 0;
  min-height: 100px;
}

.widget-display {
  position: relative;
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-wrapper-fullscreen {
    position: absolute;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    left: -220px;
    top: -44px;
  }
}

.table-widget-editor {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  overflow: auto;
  width: 100%;
  padding: 10px 10px 0;

  .widget-option {
    .fa-times {
      display: inline;
      margin-left: 5px;
      opacity: .75;
      cursor: pointer;
      color: #495057;
    }

    label, .columns_number_selector {
      text-align: center;
    }

    min-width: 200px;
    margin-bottom: 10px;
    width: 100%;

    .column_number {
      margin: 0 10px 0;
    }

    .columns_number {
      background: none;
      border: none;
      width: auto;
    }

    * {
      width: 100%;
    }
  }
}

button.columns_number {
  background: none;
  border: none;
  width: auto;
}

.widget-editor {
  display: flex;
  flex-flow: column wrap;
  overflow: auto;
  width: 100%;
  padding: 10px 10px 0;
}

.widget-option {
  flex: 1 0 auto;
}

.widget .widget-option {
  align-self: center;
}

.widget .widget-editor .widget-option {
  align-self: flex-start;
}

.workspace {
  &__sizechanger {
    background-color: #f3f3f3;
    height: .5em;
    cursor: row-resize;
    border-top: 1px solid #ced4da;
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-right: 1px solid #ced4da;

    .dropdown-menu.show {
      max-height: 200px;
      overflow: auto;
    }
  }
}

.editor__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow: hidden;
  cursor: text;

}

.query-editor {
  display: flex;
}

.variable-editor {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}

.CodeMirror-hints.graphiql {
  position: fixed;
  transform: translate(300px, 100px);
}

.variable-editor > .CodeMirror {
  height: auto !important;
}


.handle {
  flex: 0 0 auto;
}

.CodeMirror {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.handle {
  background: #f7f7f7;
  width: 100%;
  height: .666em;
  position: relative;
  user-select: none;
  cursor: row-resize;
  border-top: 1px solid #ced4da;
  font-size: 12px;
  font-feature-settings: "c2sc";
  font-variant: small-caps;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 14px;
  padding-left: 10px;
}

.handle:hover {
  background: #f0f0f0;
}

.handle:hover:before {
  color: #000;
}

.sizeChanger {
  border: 5px solid #f3f3f3;
  cursor: col-resize;

  &:hover {
    border: 5px solid #f0f0f0;
  }
}

.execute-button {
  position: absolute;
  border-radius:$border-radius;
  width: 71px;
  height: 54px;
  line-height: 12px;
  font-size: 12px;
  z-index: 10;
  top: 45%;
  right: 0;
  left: calc(50% - 35px);
  border: 0.1px solid transparent;

  background:$base-gradient;

  @include transition-btn;

  //background:linear-gradient(180deg, rgba(202, 53, 104, 0.0784313725) 0%, #FFF6F9 100%);
  background-size: 25px 25px;
  background-position-x: 60%;
  &:before {
    content: "";
    position: absolute;
    border-radius:$border-radius;

    inset: 0;
    background: linear-gradient(135deg, #71D89A, #bda6ff, #dab0ff, #71D89A);
    background-size: 300% 300%;
    animation: borderAnimation 4s ease infinite;
    z-index: -1;
  }

  &:after {
    content: "";
    position: absolute;
    border-radius:$border-radius;

    inset: 1px;
    background: linear-gradient(135deg, #FFFFFF, #F4F0FF);
    z-index: -1;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    transform: translateX(4px);
  }

  &__text {
    display: flex;
    font-size: 11px;
    color: $base-font-color;
  }

  &:focus {
    //border: 1px solid $color-primary-active;
    box-shadow: none;
    outline: transparent;

  }

  &:hover, &:active, &:target {
    box-shadow: none;

    //@include hover-btn

  }
}

@keyframes borderAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
//.execute-button:active:not(:disabled) {
//  -webkit-box-shadow: rgb(0, 0, 0) 4px 4px 15px 0px inset, 5px 5px 15px 5px rgba(0,0,0,0);
//  box-shadow: rgb(0, 0, 0) 4px 4px 15px 0px inset, 5px 5px 15px 5px rgba(0,0,0,0);
//}
//
//.execute-button:hover {
//}

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #555 !important
}

.bitqueditor__toolbar {
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
  cursor: default;
  display: flex;
  flex-direction: row;
  min-height: 54px;
  padding: 7px 14px 6px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.over-wrapper {
  position: relative;
  display: none;

  &.active {
    display: flex;
  }

  // flex: 1 1 100%;
  height: calc(100% - #{$top-bar-height});
}

.topBarWrap {
  display: flex;
}

.topBar {
  gap: 8px;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
  cursor: default;
  display: flex;
  flex-direction: row;
  flex: 1 1;
  height: $top-bar-height;
  overflow-y: visible;
  padding: 7px 14px 6px;
  padding-left: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__logo {
    height: 40px;
    margin: 0 20px 0 10px;

    &__img {
      height: 100%;
    }
  }

  &__button {
    display: inline-block;
    padding: 5px 11px 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background: $base-gradient;
    font-size: 0.9rem;
    color: $base-font-color;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;

    &:hover {
      transform: translateY(-0.5px);
      box-shadow: 0 6px 14px rgba($border-color, 0.25);
      border-color: $color-primary-hover;
    }

    &:active {
      transform: translateY(0px);
      box-shadow: 0 2px 8px rgba($color-primary-active, 0.2);
      border-color: $color-primary-hover;
    }

    &:focus {
      outline: none;
      border-color: $color-primary-hover;
      box-shadow: 0 0 0 3px rgba($border-color, 0.2);
    }
  }

  .endpointURL {
    border: none;
    font-size: 14px;
    flex: 1 1 auto
  }
}

.icon_fullscreen, .icon_csv {
  position: absolute;
  z-index: 9;
  height: 25px;
  width: 25px;
  opacity: .7;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
}

.icon_fullscreen {
  bottom: 10px;

}

.icon_csv {
  left: 65px;
  width: 30px;
  height: 30px;
}

.docExplorerWrap {
  position: relative;
  background-color: #ffffff;

  .doc-explorer-contents {
    display: contents;

    input {
      width: auto;
    }

    .graphiql-operation-title-bar {
      span > input {
        width: auto;
      }

      .toolbar-button {
        display: none;
      }
    }
  }
}

.rawcode {
  height: 100%;
}

.blinker-wrapper {
  position: absolute;
  top: 0;
  z-index: 9999;
  right: 0;
}

.blnkr {
  font-size: 5px;
  position: relative;
  left: 3px;
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

sup.text-success {
  position: absolute;
  top: 30%;
  left: 278px;
  z-index: 9000;
}

.view-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9000;
}

.flexone {
  flex: 1;
}
