.querylist {
	&__root {
		position: absolute;
		overflow: auto;
		width: 0;
		height: 100%;
		background-color: #fff;
		z-index: 10;
		right: 0;
		display: flex;
		flex-direction: column;
		transition: width .1s ease-out;
		&.active {
			width: calc(100% - 300px);
		}
		&.fullwidth {
			width: 100%;
		}
	}
	&__buttons {
		justify-content: space-evenly;
		padding: 10px 0;
	}
	&__item {
		&__wrapper {
			display: flex;
			border-bottom: 1px solid black;
			align-items: center;
		}
		font-size: 10px;
		&:hover {
			background-color: rgb(221, 221, 221);
		}
		&__name {
			margin: 0;
			font-size: 20px;
			text-transform: capitalize;
			cursor: pointer;
		}
		&__status {
			padding-right: 10px;
			cursor: default;
		}
		&__header {
			display: flex;
			align-items: center;
			gap: 10px;
		}
		&__footer {
			span {
				font-style: italic;
			}
		}
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 0 20px;
		&__tag {
			color: rgb(73, 80, 87);
			font-size: 14px;
			margin: 5px 2px;
			background-color: antiquewhite;
		}
	}
}
.bitquery-querylist-container{
	background-color:$background-gray-l ;
}
.bitquery-querylist{
	gap:10px;
	&__item{
		border-radius: 8px !important;
		box-shadow: 0 1px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

	}
	&__name{
		color:$base-font-color;
		font-size:16px;
		line-height: 21px;
	}
	&__text{
		font-size: 13px;
		line-height: 18px;
		color:$text-color-gray-l;
	}
	&__info{
		display: flex;
		align-items: center;
		gap:10px;
		font-size: 13px;
		line-height: 18px;
		color:$text-color-gray-l;
	}
}
.bitquery-divider_little {
	display: inline-block;
	width: 1px;
	content: '';
	background: $background-gray-d;

	&.bitquery-divider {
		height: 24px;
	}

	&.bitquery-divider_little {
		height: 1rem;

		@media (max-width: 991px) {
			display: none;
		}
	}
}