.promo-banner {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
    background-color: white;
    border: 1px solid #d1d5db;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    z-index: 10;
    max-width: 303px;
    cursor: pointer;
}

.promo-banner-logo {
    width: 36px;
    height: 36px;
}

.promo-banner-content {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.promo-banner-text {
    font-size: 0.875rem;
    font-weight: 500;
    color: #495057;
    margin: 0;
}

.promo-banner-dex {
    color: #495057;
    font-weight: 800;
    letter-spacing: 0.03em;
}

.promo-banner-link {
    background: linear-gradient(to top left, #DC2B0D, #FA6A22);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-decoration: none;
}

.promo-banner-close {
    background: none;
    border: none;
    color: #9ca3af;
    cursor: pointer;
    font-size: 1rem;
    transition: color 0.2s;
}

.promo-banner-close:hover {
    color: #6b7280;
}

.promo-banner-hide {
    opacity: 0;
    transform: translateY(100%);
}
