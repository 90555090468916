.bitquery-header {
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  width: 100%;
  box-shadow: 0 1px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 0 5px;
  margin-bottom: 7px;
  background-color: #ffffff;


  .bitquery-logo {
    @include flex-between-center;
    width: 200px;
    gap: 0 1px;

    &_link {
      align-items: center;
    }

    &_text, &_graph {
      font-size: 1rem;
      font-weight: 600;
    }

    &_text {
      cursor: pointer;
    }

  }

  .bitquery-graphQL {
    //color: #CE4372;
    color: $base-font-color;
  }

  .bitquery-graph {
    width: 100%;
    margin-top: 2px;
    cursor: default;
  }

  .bitquery-divider, .bitquery-divider_little {
    display: inline-block;
    width: 1px;
    content: '';
    margin-inline: 5px;
    background: $background-gray-d;

    &.bitquery-divider {
      height: 24px;
    }

    &.bitquery-divider_little {
      height: 1rem;

      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}

.bitquery-search {
  background: $base-gradient;
  height: 32px;
  width:100%;
  border-radius: 8px;
  padding: 0 16px;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 18px;
  border: 0.5px solid $border-color;

  &::placeholder {
    color: $text-color-gray-l;
  }

  &:active, &:target, &:focus {
    @include focus-style;

  }
}

.bitquery-links, #navbarDropdownMenuQueries, #nav-dropdown, #profileButton {
  @include link-style;

  &:after {
    //color: $color-primary-active;
    color: $text-color-active;
  }
}


.bitquery-nav_container {
  @include flex-between-center;
  @media (max-width: 991px) {
    align-items: start;
  }
}

#profileButton {
  background-color: inherit;
  @include hover-btn


}

.bitquery-profile-button {
  margin-left: auto;
}

.profile-email {
  color: $text-color-gray-d !important;

  &:hover, &:active, &:focus, &:target {
    cursor: default;
    text-decoration: none;
    border-color: transparent;
    background-color: transparent !important;
  }
}